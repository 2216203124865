import { Address } from '../models';

export const isStringValid = (value: string): boolean => !!(value && typeof value === 'string');

export const isAddressValid = (address: Address | undefined): boolean => {
    if (address) {
        return (
            isStringValid(address.address1) &&
            isStringValid(address.city) &&
            isStringValid(address.state) &&
            isStringValid(address.zipCode)
        );
    }

    return false;
};

import { PrefilledRegistrationDetails } from 'app/shared/models';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface LandingPageProviderProps {
    children: ReactNode;
}

interface LoginDetails {
    agentEmailAddress: string;
}

interface LandingPageContext {
    isRegistrationModalOpen: boolean;
    setIsRegistrationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isReaRegistrationModalOpen: boolean;
    setIsReaRegistrationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isBuilderRegistrationModalOpen: boolean;
    setIsBuilderRegistrationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isLoginModalOpen: boolean;
    setIsLoginModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    prefilledRegistrationDetails: PrefilledRegistrationDetails;
    setPrefilledRegistrationDetails: React.Dispatch<
        React.SetStateAction<PrefilledRegistrationDetails>
    >;
    loginDetails: LoginDetails;
    setLoginDetails: React.Dispatch<React.SetStateAction<LoginDetails>>;
    isValidEmailAddress: boolean;
    setIsValidEmailAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultRegistrationDetails = {
    agentFirstName: '',
    agentLastName: '',
    agentPhone: '',
    agentEmail: '',
    agentStateCode: '',
    nafHomesId: '',
    reaSource: '',
};

const defaultLoginDetails = {
    agentEmailAddress: '',
};

const LandingPageContext = createContext<LandingPageContext | undefined>(undefined);

export const LandingPageProvider = ({ children }: LandingPageProviderProps): JSX.Element => {
    const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
    const [isReaRegistrationModalOpen, setIsReaRegistrationModalOpen] = useState(false);
    const [isBuilderRegistrationModalOpen, setIsBuilderRegistrationModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [prefilledRegistrationDetails, setPrefilledRegistrationDetails] =
        useState<PrefilledRegistrationDetails>(defaultRegistrationDetails);
    const [loginDetails, setLoginDetails] = useState<LoginDetails>(defaultLoginDetails);
    const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);

    const value = useMemo(
        () => ({
            isRegistrationModalOpen,
            setIsRegistrationModalOpen,
            isReaRegistrationModalOpen,
            setIsReaRegistrationModalOpen,
            isBuilderRegistrationModalOpen,
            setIsBuilderRegistrationModalOpen,
            isLoginModalOpen,
            setIsLoginModalOpen,
            prefilledRegistrationDetails,
            setPrefilledRegistrationDetails,
            loginDetails,
            setLoginDetails,
            isValidEmailAddress,
            setIsValidEmailAddress,
        }),
        [
            isRegistrationModalOpen,
            setIsRegistrationModalOpen,
            isReaRegistrationModalOpen,
            setIsReaRegistrationModalOpen,
            isBuilderRegistrationModalOpen,
            setIsBuilderRegistrationModalOpen,
            isLoginModalOpen,
            setIsLoginModalOpen,
            prefilledRegistrationDetails,
            setPrefilledRegistrationDetails,
            loginDetails,
            setLoginDetails,
            isValidEmailAddress,
            setIsValidEmailAddress,
        ]
    );

    return <LandingPageContext.Provider value={value}>{children}</LandingPageContext.Provider>;
};

export const useLandingPage = (): LandingPageContext => {
    const context = useContext(LandingPageContext);
    if (!context) {
        throw new Error('useLandingPage must be used within a LandingPageProvider');
    }
    return context;
};

import { PreApprovalResponse, PreApprovalRequest } from 'app/shared/models/pre-approval.model';
import { getPreApprovalLetter } from 'app/services/loans/loans.service';
import { AsyncRequestStatus } from 'app/shared/enums';
import { toasterError } from 'app/shared/helpers';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface LoansState {
    statusGeneratePal: AsyncRequestStatus;
    generatedPal: PreApprovalResponse | null;
}

const initialState: LoansState = {
    statusGeneratePal: AsyncRequestStatus.Idle,
    generatedPal: null,
};

export const generatePalAsync = createAsyncThunk(
    'loans/preApproval',
    async (payload: PreApprovalRequest) => {
        // calling service

        const response = await getPreApprovalLetter(payload);
        return response;
    }
);

export const loansSlice = createSlice({
    name: 'loans',
    initialState,
    reducers: {
        resetPal(state) {
            state.generatedPal = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // POST Loans/preApproval
            .addCase(generatePalAsync.pending, (state) => {
                state.statusGeneratePal = AsyncRequestStatus.Pending;
            })
            .addCase(generatePalAsync.fulfilled, (state, action) => {
                state.statusGeneratePal = AsyncRequestStatus.Fulfilled;

                if (action.payload?.data?.data?.data) {
                    state.generatedPal = action.payload.data.data.data;
                }
            })
            .addCase(generatePalAsync.rejected, (state) => {
                state.statusGeneratePal = AsyncRequestStatus.Rejected;
                toasterError('Error');
            });
    },
});

export const statusGeneratePalSelector = (state: RootState): AsyncRequestStatus =>
    state.loans.statusGeneratePal;
export const generatedPalSelector = (state: RootState): PreApprovalResponse | null =>
    state.loans.generatedPal;
export const { resetPal } = loansSlice.actions;

export default loansSlice.reducer;

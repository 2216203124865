import { createApi } from '@reduxjs/toolkit/query/react';
import { httpMethod } from 'app/services/_httpClient';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { FeatureFlag } from 'app/shared/models/feature-flag/feature-flag.model';
import { FeatureFlagRequest } from './models';
import { queryParametersToString } from 'app/shared/helpers';

export const featureFlagApi = createApi({
    reducerPath: 'feature-flag',
    baseQuery: axiosBaseQuery({ baseUrl: NAF_CONNECT_API_BASE_URL }),
    endpoints: (builder) => ({
        getFeatureFlag: builder.query<boolean, FeatureFlagRequest>({
            query: (request) => {
                const { name, queryParameters } = request;

                const paramsString = queryParameters
                    ? queryParametersToString(queryParameters)
                    : '';

                const url = paramsString
                    ? `/featureFlags/${name}?${paramsString}`
                    : `/featureFlags/${name}`;

                return {
                    url,
                    method: httpMethod.Get,
                };
            },
            transformResponse: (response: { data: FeatureFlag }) => response.data.enabled,
        }),
    }),
});

export const { useGetFeatureFlagQuery } = featureFlagApi;

import { ReactNode } from 'react';

import { Milestone, MilestoneStatus } from 'app/shared/enums';
import { LoanOfficer, MilestoneStep, MilestoneTip } from 'app/shared/models';

export const getCurrentMilestoneStepFromMilestoneData = (
    milestones: MilestoneStep[]
): MilestoneStep | undefined => {
    for (let i = milestones.length - 1; i >= 0; i--) {
        if (milestones[i].isActive) {
            return milestones[i];
        }
    }
    return undefined;
};

export const getMilestoneText = (
    milestone: Milestone | undefined,
    canceled: boolean | undefined,
    isPrimary: boolean | undefined,
    origin: 'client-card' | 'client-details'
): string | ReactNode => {
    let milestoneText = '';

    if (canceled) {
        return (
            <>
                Loan <br />
                Canceled
            </>
        );
    }
    switch (milestone) {
        case Milestone.ReferralSubmitted:
            return (
                <>
                    Referral <br />
                    Submitted
                </>
            );

        case Milestone.PreApproved:
            milestoneText = 'Pre-Approved';
            break;
        case Milestone.SubmittedToUw:
            return (
                <>
                    Submitted to <br />
                    Underwriting
                </>
            );
        case Milestone.ConditionallyApproved:
            if (!isPrimary && origin === 'client-details') {
                return (
                    <>
                        <div>Under</div>
                        <div>Review</div>
                    </>
                );
            }
            return (
                <>
                    <div>Conditionally</div>
                    <div>Approved</div>
                </>
            );
        case Milestone.FinalReview:
            return (
                <>
                    Final
                    <br />
                    Review
                </>
            );
        case Milestone.ClearedToClose:
            return (
                <>
                    Cleared <br />
                    to Close
                </>
            );

        case Milestone.Funded:
            return <>Funded</>;
            break;

        default:
            return (
                <>
                    Referral <br />
                    Submitted
                </>
            );
    }

    return milestoneText;
};

export const getMilestoneTipInfo = (
    milestone: Milestone | undefined,
    canceled: boolean,
    currentStatus: MilestoneStatus,
    loanOfficer: LoanOfficer
): MilestoneTip => {
    const tipInfo: MilestoneTip = {} as MilestoneTip;
    let fullName = '';

    if (loanOfficer) {
        fullName = `${loanOfficer?.firstName} ${loanOfficer?.lastName}`;
    }

    if (canceled) {
        tipInfo.title = 'Loan Canceled: ';
        tipInfo.tip = 'Please contact your loan officer for more information.';
        return tipInfo;
    }

    switch (milestone) {
        case Milestone.ReferralSubmitted:
            tipInfo.title = 'Referral Submitted: ';
            tipInfo.tip = 'Thank you for sending us your client.';
            return tipInfo;

        case Milestone.PreApproved:
            tipInfo.title = 'Pre-Approved: ';
            tipInfo.tip =
                'Client has met preliminary requirements, but loan approval is conditional upon further review.';
            return tipInfo;
        case Milestone.SubmittedToUw:
            tipInfo.title = 'Submitted to Underwriting: ';
            tipInfo.tip =
                'Income, asset, credit, and property documents are under review for verification.';
            return tipInfo;
        case Milestone.ConditionallyApproved:
            if (currentStatus === MilestoneStatus.Secondary) {
                tipInfo.title = 'Under Review: ';
                tipInfo.tip = (
                    <>
                        Loan file is under additional review.
                        <br />
                        Your Loan Officer, {fullName} will reach out with any additional information
                        if needed.
                    </>
                );
                return tipInfo;
            }

            tipInfo.title = 'Conditionally Approved: ';
            tipInfo.tip =
                'We are actively underwriting the loan file and may need additional information.';
            return tipInfo;

        case Milestone.FinalReview:
            tipInfo.title = 'Final Review: ';
            tipInfo.tip = 'We are actively reviewing the file and may need additional information.';
            return tipInfo;
        case Milestone.ClearedToClose:
            tipInfo.title = 'Cleared to Close: ';
            tipInfo.tip = 'All conditions are cleared and loan is approved for closing.';
            return tipInfo;

        case Milestone.Funded:
            tipInfo.title = 'Funded: ';
            tipInfo.tip = 'Loan is closed and funded.';
            return tipInfo;
        default:
            break;
    }

    return tipInfo;
};

export const getMilestoneTitle = (milestone: Milestone | undefined): string => {
    const map: { [key in Exclude<Milestone, Milestone.Undefined>]: string } = {
        [Milestone.ReferralSubmitted]: 'Referral Submitted',
        [Milestone.PreApproved]: 'Pre-Approved',
        [Milestone.SubmittedToUw]: 'Submitted to Underwriting',
        [Milestone.ConditionallyApproved]: 'Conditionally Approved',
        [Milestone.ClearedToClose]: 'Cleared to Close',
        [Milestone.Funded]: 'Funded',
        [Milestone.FinalReview]: 'Final Review',
        [Milestone.Canceled]: 'Loan Canceled',
    };

    return milestone ? map[milestone ?? ''] : '';
};

export const getStepperLabelModifier = (
    isFirstIndex: boolean,
    isLastIndex: boolean,
    isCanceled: boolean
): string => {
    let modifier = '';

    if (isFirstIndex) {
        modifier = '--first';
    }

    if (isLastIndex) {
        modifier = '--last';
    }

    if (isLastIndex && isCanceled) {
        modifier = '--lastCanceled';
    }

    return modifier;
};

export const isFirstMilestone = (index: number): boolean => index === 0;

export const isLastMilestone = (array: MilestoneStep[], index: number): boolean =>
    index === array.length - 1;

import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

interface DrawerProviderProps {
    children: ReactNode;
}

interface DrawerContext {
    isSidebarOpen: boolean;
    toggleSideBar: () => void;
    isSettingsOpen: boolean;
    toggleSettings: () => void;
    isResourceCenterOpen: boolean;
    toggleResourceCenter: () => void;
}

const DrawerContext = createContext<DrawerContext>({
    isSidebarOpen: false,
    toggleSideBar: () => {},
    isSettingsOpen: false,
    toggleSettings: () => {},
    isResourceCenterOpen: false,
    toggleResourceCenter: () => {},
});

export const DrawerProvider = ({ children }: DrawerProviderProps): JSX.Element => {
    const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [isResourceCenterOpen, setResourceCenterOpen] = useState<boolean>(false);

    const toggleSideBar = useCallback(() => {
        setSidebarOpen((prev) => !prev);
    }, [setSidebarOpen]);

    const toggleSettings = useCallback(() => {
        setSettingsOpen((prev) => !prev);
    }, [setSettingsOpen]);

    const toggleResourceCenter = useCallback(() => {
        setResourceCenterOpen((prev) => !prev);
    }, [setResourceCenterOpen]);

    const value = useMemo(
        () => ({
            isSidebarOpen,
            toggleSideBar,
            isSettingsOpen,
            toggleSettings,
            isResourceCenterOpen,
            toggleResourceCenter,
        }),
        [
            isSidebarOpen,
            toggleSideBar,
            isSettingsOpen,
            toggleSettings,
            isResourceCenterOpen,
            toggleResourceCenter,
        ]
    );

    return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export const useDrawer = (): DrawerContext => useContext(DrawerContext);

export enum NotificationSettingType {
    NewNotes = 1,
    ReferralSubmitted,
    LoanPreApproved,
    LoanSubmittedToUw,
    LoanClearedToClose,
    LoanFunded,
    ClientUpdates,
    LoanOfficerAssigned,
    PreQualified,
    AppraisalCleared,
    ClientAssignedREA,
}

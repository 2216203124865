import { getRealEstateAgentId } from 'app/services';
import { httpMethod } from 'app/services/_httpClient';
import { BAPRequest } from 'app/services/realestate-agent/models/bap-request.model';
import { EmailConsentRequest } from 'app/services/realestate-agent/models/email-consent-request.model';
import { Client, ClientDeal, DealNote } from 'app/shared/models';

import { createApi } from '@reduxjs/toolkit/query/react';
import {
    toasterSuccess,
    toasterError,
    localStorageHelper,
    prepareCsvLink,
    clickLink,
    formatDate,
} from 'app/shared/helpers';

import { axiosBaseApiQuery } from '../axiosBaseQuery';

import { ReferralSuccess } from 'app/shared/toaster-content/toaster-content';
import {
    AddDealNoteRequest,
    AddLONoteRequest,
    ClientsReportRequest,
    ClientsReportResponse,
    GetClientDealNotesRequest,
    GetClientDealRequest,
    ReferClientRequest,
} from './models';
import { PostClientHoaInfoRequest } from './models/post-client-hoa-info-request.model';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';

export const clientsApi = createApi({
    reducerPath: 'clients-api',
    baseQuery: axiosBaseApiQuery({ baseUrl: NAF_CONNECT_API_BASE_URL }),
    endpoints: (builder) => ({
        getClients: builder.query<Client[] | null, void>({
            query: () => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/clients`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: Client[] }) => response.data,
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                const { data: clients } = await queryFulfilled;

                if (clients) {
                    const reaId = getRealEstateAgentId();
                    const pendingClients = localStorageHelper.get<Client[]>(`${reaId}-pc`) || [];

                    if (pendingClients.length > 0) {
                        if (
                            pendingClients.some(
                                (pc) =>
                                    clients?.some(
                                        (client) =>
                                            client.firstName === pc.firstName &&
                                            client.lastName === pc.lastName
                                    )
                            )
                        ) {
                            const updatedPendingClients = pendingClients.filter(
                                (pc) =>
                                    !clients?.some(
                                        (client) =>
                                            client.firstName === pc.firstName &&
                                            client.lastName === pc.lastName
                                    )
                            );

                            localStorageHelper.set(`${reaId}-pc`, updatedPendingClients);

                            dispatch(
                                clientsApi.endpoints.getClients.initiate(undefined, {
                                    forceRefetch: true,
                                })
                            );
                        }
                    }

                    localStorageHelper.set(`${reaId}-cc`, clients);
                }
            },
        }),
        getClientDeal: builder.query<ClientDeal, GetClientDealRequest>({
            query: ({ dealId, loanId }) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${dealId}/loans/${loanId}`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: ClientDeal }) => response.data,
        }),
        getClientDealNotes: builder.query<DealNote[], GetClientDealNotesRequest>({
            query: ({ dealId }) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${dealId}/notes`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: DealNote[] }) => response.data,
        }),
        addClientDealNote: builder.mutation<DealNote, AddDealNoteRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${data.dealId}/notes`,
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: DealNote }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: note } = await queryFulfilled;
                    const reaId = getRealEstateAgentId();
                    const request: GetClientDealNotesRequest = {
                        reaId,
                        dealId: arg.dealId,
                    };

                    dispatch(
                        clientsApi.util.updateQueryData('getClientDealNotes', request, (draft) => {
                            if (draft != null) {
                                draft.unshift(note);
                            } else {
                                draft = [note];
                            }
                        })
                    );
                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        referClient: builder.mutation<boolean, ReferClientRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/clients`,
                method: httpMethod.Post,
                data,
                suppressRedirect: true,
            }),
            async onQueryStarted(referral, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    const reaId = getRealEstateAgentId();

                    const pendingClients = localStorageHelper.get<Client[]>(`${reaId}-pc`) || [];

                    const client: Client = {
                        firstName: referral.borrowers[0].firstName,
                        lastName: referral.borrowers[0].lastName,
                        referredDate: new Date(),
                    };

                    pendingClients.unshift(client);

                    localStorageHelper.set(`${reaId}-pc`, pendingClients);

                    dispatch(
                        clientsApi.endpoints.getClients.initiate(undefined, { forceRefetch: true })
                    );

                    toasterSuccess(
                        ReferralSuccess(
                            referral.borrowers[0].firstName,
                            referral.borrowers[0].lastName
                        )
                    );
                } catch {
                    toasterError('Client referral was unsuccessful.');
                }
            },
        }),
        sendConsentEmail: builder.mutation<string, EmailConsentRequest>({
            query: ({ clientId, loanId }) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${clientId}/loans/${loanId}/consent`,
                method: httpMethod.Post,
            }),
        }),
        sendBAPRequest: builder.mutation<string, BAPRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${data.clientId}/loans/${
                    data.loanId
                }/buyerAcceptedProgram`,
                method: httpMethod.Post,
                data,
            }),
        }),
        sendHoaInfoRequest: builder.mutation<string, PostClientHoaInfoRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${data.dealId}/loans/${
                    data.loanId
                }/hoa`,
                method: httpMethod.Post,
                data,
            }),
        }),
        sendLOMessageRequest: builder.mutation<string, AddLONoteRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${data.dealId}/loans/${
                    data.loanId
                }/preApprovals`,
                method: httpMethod.Put,
                data,
            }),
        }),
        clientsReport: builder.mutation<ClientsReportResponse, ClientsReportRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/clientsReport`,
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: ClientsReportResponse }) => response.data,
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data: response } = await queryFulfilled;

                    if (response?.csvString) {
                        const link = prepareCsvLink(
                            response.csvString,
                            `nafc_${formatDate(new Date(), 'MMDDYY')}`
                        );

                        clickLink(link);
                    }
                } catch {
                    toasterError('Error');
                }
            },
        }),
    }),
});

export const {
    useGetClientsQuery,
    useGetClientDealQuery,
    useGetClientDealNotesQuery,
    useAddClientDealNoteMutation,
    useReferClientMutation,
    useSendConsentEmailMutation,
    useSendBAPRequestMutation,
    useSendHoaInfoRequestMutation,
    useLazyGetClientDealQuery,
    useSendLOMessageRequestMutation,
    useClientsReportMutation,
} = clientsApi;

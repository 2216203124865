import { axiosBaseQuery } from '../axiosBaseQuery';
import { httpMethod } from 'app/services/_httpClient';
import { NAF_CONNECT_API_BASE_URL, NAF_CONNECT_API_KEY } from 'app/shared/helpers/settings.helper';
import { TagMaps, Tile } from 'app/shared/models';
import { createApi } from '@reduxjs/toolkit/query/react';
import { TilesResponse } from './models';

export const settingsApi = createApi({
    reducerPath: 'settings-api',
    baseQuery: axiosBaseQuery({ baseUrl: `${NAF_CONNECT_API_BASE_URL}/settings/` }),
    endpoints: (builder) => ({
        getResources: builder.query<Tile[], void>({
            query: () => ({
                url: 'resources',
                method: httpMethod.Get,
                headers: {
                    'X-API-Key': NAF_CONNECT_API_KEY,
                },
            }),
            transformResponse: (response: { data: Tile[] }) => response.data,
        }),
        getTiles: builder.query<TilesResponse, void>({
            query: () => ({
                url: 'tiles',
                method: httpMethod.Get,
                headers: {
                    'X-API-Key': NAF_CONNECT_API_KEY,
                },
            }),
            transformResponse: (response: { data: TilesResponse }) => response.data,
        }),
        getTemplateTagMaps: builder.query<TagMaps, void>({
            query: () => ({
                url: 'templateTagMaps',
                method: httpMethod.Get,
                headers: {
                    'X-API-Key': NAF_CONNECT_API_KEY,
                },
            }),
            transformResponse: (response: { data: TagMaps }) => response.data,
        }),
    }),
});

export const { useGetResourcesQuery, useGetTilesQuery, useGetTemplateTagMapsQuery } = settingsApi;

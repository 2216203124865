import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { ValidateEmailAddressRequest, ValidateEmailAddressResponse } from './models/auth.models';
import { httpMethod } from 'app/services/_httpClient';

export const authApi = createApi({
    reducerPath: 'auth-api',
    baseQuery: axiosBaseQuery({ baseUrl: `${NAF_CONNECT_API_BASE_URL}/authentication/` }),
    endpoints: (builder) => ({
        validateEmailAddress: builder.mutation<
            ValidateEmailAddressResponse,
            ValidateEmailAddressRequest
        >({
            query: (data) => ({
                url: 'validateEmailAddress',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: ValidateEmailAddressResponse }) => response.data,
        }),
    }),
});

export const { useValidateEmailAddressMutation } = authApi;

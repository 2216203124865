export interface State {
    id: number;
    code: string;
    name: string;
}

const states: State[] = [
    {
        id: 0,
        name: 'Hawaii',
        code: 'HI',
    },
    {
        id: 1,
        name: 'Alabama',
        code: 'AL',
    },
    {
        id: 2,
        name: 'Alaska',
        code: 'AK',
    },
    {
        id: 3,
        name: 'Arizona',
        code: 'AZ',
    },
    {
        id: 4,
        name: 'Arkansas',
        code: 'AR',
    },
    {
        id: 5,
        name: 'California',
        code: 'CA',
    },
    {
        id: 6,
        name: 'Colorado',
        code: 'CO',
    },
    {
        id: 7,
        name: 'Connecticut',
        code: 'CT',
    },
    {
        id: 8,
        name: 'Delaware',
        code: 'DE',
    },
    {
        id: 9,
        name: 'District of Columbia',
        code: 'DC',
    },
    {
        id: 10,
        name: 'Florida',
        code: 'FL',
    },
    {
        id: 11,
        name: 'Georgia',
        code: 'GA',
    },
    {
        id: 12,
        name: 'Idaho',
        code: 'ID',
    },
    {
        id: 13,
        name: 'Illinois',
        code: 'IL',
    },
    {
        id: 14,
        name: 'Indiana',
        code: 'IN',
    },
    {
        id: 15,
        name: 'Iowa',
        code: 'IA',
    },
    {
        id: 16,
        name: 'Kansas',
        code: 'KS',
    },
    {
        id: 17,
        name: 'Kentucky',
        code: 'KY',
    },
    {
        id: 18,
        name: 'Louisiana',
        code: 'LA',
    },
    {
        id: 19,
        name: 'Maine',
        code: 'ME',
    },
    {
        id: 20,
        name: 'Maryland',
        code: 'MD',
    },
    {
        id: 21,
        name: 'Massachusetts',
        code: 'MA',
    },
    {
        id: 22,
        name: 'Michigan',
        code: 'MI',
    },
    {
        id: 23,
        name: 'Minnesota',
        code: 'MN',
    },
    {
        id: 24,
        name: 'Mississippi',
        code: 'MS',
    },
    {
        id: 25,
        name: 'Missouri',
        code: 'MO',
    },
    {
        id: 26,
        name: 'Montana',
        code: 'MT',
    },
    {
        id: 27,
        name: 'Nebraska',
        code: 'NE',
    },
    {
        id: 28,
        name: 'Nevada',
        code: 'NV',
    },
    {
        id: 29,
        name: 'New Hampshire',
        code: 'NH',
    },
    {
        id: 30,
        name: 'New Jersey',
        code: 'NJ',
    },
    {
        id: 31,
        name: 'New Mexico',
        code: 'NM',
    },
    {
        id: 32,
        name: 'North Carolina',
        code: 'NC',
    },
    {
        id: 33,
        name: 'North Dakota',
        code: 'ND',
    },
    {
        id: 34,
        name: 'Ohio',
        code: 'OH',
    },
    {
        id: 35,
        name: 'Oklahoma',
        code: 'OK',
    },
    {
        id: 36,
        name: 'Oregon',
        code: 'OR',
    },
    {
        id: 37,
        name: 'Pennsylvania',
        code: 'PA',
    },
    {
        id: 38,
        name: 'Rhode Island',
        code: 'RI',
    },
    {
        id: 39,
        name: 'South Carolina',
        code: 'SC',
    },
    {
        id: 40,
        name: 'South Dakota',
        code: 'SD',
    },
    {
        id: 41,
        name: 'Tennessee',
        code: 'TN',
    },
    {
        id: 42,
        name: 'Texas',
        code: 'TX',
    },
    {
        id: 43,
        name: 'Utah',
        code: 'UT',
    },
    {
        id: 44,
        name: 'Vermont',
        code: 'VT',
    },
    {
        id: 45,
        name: 'Virginia',
        code: 'VA',
    },
    {
        id: 46,
        name: 'Washington',
        code: 'WA',
    },
    {
        id: 47,
        name: 'West Virginia',
        code: 'WV',
    },
    {
        id: 48,
        name: 'Wisconsin',
        code: 'WI',
    },
    {
        id: 49,
        name: 'Wyoming',
        code: 'WY',
    },
    // NY should remain id 51 to match the model Marketing team has
    {
        id: 51,
        name: 'New York',
        code: 'NY',
    },
    // PR should remain id 52 to match the model Marketing team has
    {
        id: 52,
        name: 'Puerto Rico',
        code: 'PR',
    },
];

export const statesById = [...states].sort((a, b) => a.id - b.id);
export const statesByName = [...states].sort((a, b) => a.name.localeCompare(b.name));
export const statesByCode = [...states].sort((a, b) => a.code.localeCompare(b.code));

import { createApi } from '@reduxjs/toolkit/query/react';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { axiosBaseApiQuery } from 'app/store/api/axiosBaseQuery';
import { getRealEstateAgentId, httpMethod } from 'app/services/_httpClient';
import { PreApprovalRequest, PreApprovalResponse } from 'app/shared/models/pre-approval.model';

export const loansApi = createApi({
    reducerPath: 'loans-api',
    baseQuery: axiosBaseApiQuery({ baseUrl: NAF_CONNECT_API_BASE_URL }),
    endpoints: (builder) => ({
        getPreApprovalLetter: builder.mutation<PreApprovalResponse, PreApprovalRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/deals/${data.dealId}/loans/${
                    data.loanId
                }/preApprovals`,
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: { data: PreApprovalResponse } }) =>
                response.data.data,
        }),
    }),
});

export const { useGetPreApprovalLetterMutation } = loansApi;

export const createLink = (href: string, fileName?: string): HTMLAnchorElement => {
    const link = document.createElement('a');

    link.href = href;
    link.download = `${fileName}`;

    return link;
};

export const clickLink = (link: HTMLAnchorElement): void => {
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click'));
    document.body.removeChild(link);
};

export const prepareCsvLink = (data: string, fileName: string): HTMLAnchorElement => {
    const csvData = new Blob([data], {
        type: 'text/csv',
    });

    const csvURL = URL.createObjectURL(csvData);

    return createLink(csvURL, `${fileName}.csv`);
};

// toastify styles import should remain above app styles import
import 'react-toastify/dist/ReactToastify.css';

import { lazy, ReactElement, Suspense, useContext } from 'react';

import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import styles from './App.module.scss';
import { EnvContext } from './contexts/environment/environment.context';
import {
    CoBrandingAgreement,
    ElectronicConsentAgreement,
    PrivacyPolicy,
    TermsOfUse,
} from './features/landing-page/legal';
import CustomCircularProgress from './shared/components/custom-circular-progress/custom-circular-progress.component';
import { AdminSignInProvider } from './contexts/admin-sign-in/admin-sign-in.context';

const LandingPage = lazy(() => import('./features/landing-page/landing-page.component'));
const EmailActivity = lazy(() => import('./features/email-activity/email-activity.component'));
const TextActivity = lazy(() => import('./features/text-activity/text-activity.component'));
const NotFound = lazy(() => import('./features/layout/not-found/not-found.component'));
const Layout = lazy(() => import('./features/layout/layout.component'));
const Clients = lazy(() => import('./features/clients/clients.component'));
const ClientDeal = lazy(() => import('./features/clients/client-deal/client-deal.component'));
const Profile = lazy(() => import('./features/settings/profile/profile.component'));
const LoanOfficers = lazy(
    () => import('./features/settings/loan-officers/loan-officers.component')
);
const Communications = lazy(
    () => import('./features/settings/communications/communications.component')
);
const MonthlyPayment = lazy(
    () => import('./features/calculators/monthly-payment/monthly-payment.component')
);
const LoanAmortization = lazy(
    () => import('./features/calculators/loan-amortization/loan-amortization.component')
);
const Help = lazy(() => import('./features/help/help.component'));
const FaqsList = lazy(() => import('./features/faqs-list/faqs-list.component'));
const ReferClient = lazy(() => import('./features/refer-client/refer-client.component'));
const Admin = lazy(() => import('./features/admin/admin.component'));
const Marketing = lazy(() => import('./features/marketing/marketing.component'));
const MarketingCollateralDeatils = lazy(
    () => import('./features/marketing-collateral-details/marketing-collateral-details.component')
);
const VendorLeads = lazy(() => import('./features/vendor-leads/vendor-leads.component'));
const Welcome = lazy(() => import('./features/welcome/welcome.component'));
const PartnerShipsAndProducts = lazy(
    () =>
        import('./features/resources/partnerships-and-products/partnerships-and-products.component')
);
const NAFConnectTour = lazy(
    () => import('./features/resources/naf-connect-tour/naf-connect-tour.component')
);
const TemplateViewer = lazy(
    () => import('./shared/components/template-viewer/template-viewer.component')
);

export const App = (): ReactElement => {
    const env = useContext(EnvContext);
    const { NC_CANONICAL_URL = '' } = env;
    return (
        <div className={styles.App}>
            <Helmet>
                <link rel="canonical" href={NC_CANONICAL_URL} />
            </Helmet>
            <Suspense fallback={<CustomCircularProgress />}>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/login" element={<LandingPage />} />
                    <Route path="/register" element={<LandingPage />} />
                    <Route path="terms-of-use" element={<TermsOfUse />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route
                        path="electronic-consent-agreement"
                        element={<ElectronicConsentAgreement />}
                    />
                    <Route path="co-branding-agreement" element={<CoBrandingAgreement />} />
                    <Route path="emailactivity" element={<EmailActivity />} />
                    <Route path="textmessageactivity" element={<TextActivity />} />
                    <Route path="vendor/:vendor/:action" element={<VendorLeads />} />
                    <Route
                        path="admin"
                        element={
                            <AdminSignInProvider>
                                <Admin />
                            </AdminSignInProvider>
                        }
                    />
                    <Route path="rea" element={<Layout />}>
                        <Route path="clients" element={<Clients />} />
                        <Route path="welcome" element={<Welcome />} />
                        <Route path="refer-client" element={<ReferClient />} />
                        <Route path="deals/:dealId/loans/:loanId" element={<ClientDeal />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="loan-officers" element={<LoanOfficers />} />
                        <Route path="communications" element={<Communications />} />
                        <Route path="monthly-payment" element={<MonthlyPayment />} />
                        <Route path="loan-amortization" element={<LoanAmortization />} />
                        <Route path="help" element={<Help />} />
                        <Route path="faqs" element={<FaqsList />} />
                        <Route path="marketing" element={<Marketing />} />
                        <Route path="tour" element={<NAFConnectTour />} />
                        <Route
                            path="partnerships-and-products"
                            element={<PartnerShipsAndProducts />}
                        />
                        <Route
                            path="tour/:templateId"
                            element={<TemplateViewer isNAFConnectTour />}
                        />
                        <Route
                            path="partnerships-and-products/:templateId"
                            element={<TemplateViewer />}
                        />
                        <Route
                            path="marketing/:collateralTemplateId"
                            element={<MarketingCollateralDeatils />}
                        />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
            <ToastContainer toastStyle={{ backgroundColor: 'white' }} />
        </div>
    );
};

export default App;
